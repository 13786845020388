import * as React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import { chapterLink, chapterContainer } from '../components/layout.module.css'


const ByTopic = ({data}) => {
  return (
    <Layout pageTitle="Topics">
      <div className={chapterContainer}>
        {data.chapterGroups.group.map(node => (
            <Link to={"/" + node.fieldValue} className={chapterLink}>
              {node.fieldValue}</Link>
        ))
      }
      </div>
    </Layout>
  )
}

export const query = graphql`
query {
    chapterGroups: allMdx(sort: {fields: frontmatter___chapter, order: ASC}) {
      group(field: frontmatter___chapterTitle) {
        fieldValue
        nodes {
          frontmatter {
            sectionTitle
            chapterTitle
            title
            slug
          }
        }
      }
    }
  }
`

export default ByTopic